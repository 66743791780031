import React from 'react';

const ChangePassword = () => {
    
    return ( 
        <div> Change Password</div>

     );
}
 
export default ChangePassword;