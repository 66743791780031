import React, { Component } from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { v4 as uuidv4 } from 'uuid';

import MapMarker from './MapMarker';
import SingleUserMap from './SingleUserMap';
import ApiServices from '../../../../services/ApiServices';

class MapContainer extends Component { 

  constructor(props) {
    super(); 
    this.state = {     
      usersLocation: [],
      loader: false,
      center: { 
        lat: 0,
        lng: 0
      }
    };     
  } 


  componentDidMount() {
      this.getLocationsOnLoad();      
  }

  async getLocationsOnLoad(){
    let response =  await ApiServices.getLocations();
   
    if(response.length > 0){
      this.setState({ usersLocation: response });
      this.setCenter(response);
    }
  }


  setCenter = (data) => {

    let lat =  parseFloat(Object.entries(data)[0][1]['latitude']);
    let lng =  parseFloat(Object.entries(data)[0][1]['longitude']);

    this.setState(prevState => ({
      center: { ...prevState.center, lat, lng}
    }));
  }

    
    render() {

        const containerStyle = {
            width: '100%',
            height: 'calc(100vh - 250px)',
            position: 'relative'
          };

          const center = {
            lat: this.state.center.lat,
            lng: this.state.center.lng
          };

        return (
            <div className="pb-3 pt-3">   
              <LoadScript googleMapsApiKey="AIzaSyBNjBQDnifMASSz34D5I3FzkBeiFgKAn3A">

                { (this.props.isFiltered)  
                  ?

                    <SingleUserMap data= { this.props.singleData} />                
                  :                
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={14}>
                        { 
                          this.state.usersLocation.map((location) => (
                            <MapMarker key= {uuidv4() } data={location} />
                          )) 
                        }
                    </GoogleMap>
               }
                
              </LoadScript> 
            </div>        
        );
      }
}


export default MapContainer;