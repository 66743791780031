import React from 'react';
import { Polyline } from '@react-google-maps/api';
import { v4 as uuid4 } from 'uuid';


const PolylineMarker = (props) =>  { 

        return (

            props.lineCoordinates.map((value, i) => (

            <Polyline key= { uuid4() }
                path={props.lineCoordinates.slice(i, i+2)}
                geodesic={true}
                options={{
                    strokeColor: "#ff2527",
                    strokeOpacity: 0.75,
                    strokeWeight: 2,
                    icons: [
                        {
                            icon: {path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW},
                            offset: "0",
                            repeat: "200px"
                        }
                    ]
                }}
            /> 

            ))
            

        
        );
      
}

export default PolylineMarker;