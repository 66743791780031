import React from 'react';
import { IconContext } from "react-icons";
import { FaHeart } from 'react-icons/fa';

import {Link} from 'react-router-dom'


class Footer extends React.Component {
    
    render() { 
        return ( 
        
        <div className="footer">                
            <div>Developed and Managed with 
            <IconContext.Provider value={{ color: "red", className: "footer-icon" }}>
                <FaHeart/>
            </IconContext.Provider>
                by <Link to={"http://bit.ly/systdev"} target="_blank">Systway</Link>
            </div>
        </div>
      );
    }
}
 
export default Footer;