import axios from 'axios';

import Auth from './auth';

const API_URL = 'https://tracking-service.kpstone.org/api/v1';

class ApiService {

     constructor(){
        this.token = Auth.getAccessToken();
     }   
    

   async getLocations(){

        const locationApiURL = API_URL+'/locations?token='+this.token;

        try{

            const response =  await axios.get(locationApiURL);
            
            return response.data.data;

        }catch(error){
            return error.response.data;
         } 
    }

    async getUserLocations(user, date, formDate, toDate){

        const locationUserApiURL = API_URL+'/locations?date='+date+'&from_time='+formDate+'&to_time='+toDate+'&user_id='+ user +'&token='+this.token;
        

        try{

            const response =  await axios.get(locationUserApiURL);
            return response.data.data;

        }catch(error){
            return error.response.data;
         } 
    }


    async getUsers(){

        const userApiURL = API_URL+'/users?token='+this.token;

        try{

            const response =  await axios.get(userApiURL);                
            return response.data;

        }catch(error){
            return error.response.data;
         } 

    }

}

export default new ApiService();