import React, { Component } from 'react';
import { Col, Input, Row } from 'reactstrap';
import ApiServices from '../../../services/ApiServices';


class Filter extends Component {

    constructor(props){
        super();
    }

    state = { 
        users: [],
        singleUserLocations: [],
        userId : null,
        date: null,
        formTime: null,
        toTime: null
     }

    componentDidMount(){
        this.getUsersOnLoad();
    } 

    async getUsersOnLoad(){

        let response =  await ApiServices.getUsers();       
        this.setState({ users: response });
    }

    async handleFilterSubmit(e){

        e.preventDefault();

        let userId = this.state.userId;
        let date = this.state.date;
        let formTime = this.state.formTime;
        let toTime = this.state.toTime;

        var d = new Date();

        if(!date){ date = d.toISOString().slice(0, 10)}
        if(!formTime){ formTime = '';}
        if(!toTime){ toTime = '';}

        let response =  await ApiServices.getUserLocations(userId, date, formTime, toTime ); 

        this.setState({ singleUserLocations: response });

        this.props.callback(this.state.singleUserLocations, true);
    }

    handleFilterUserChange(event){

        this.setState({userId: event.target.value});

    }

    handleFilterDate(event){

        this.setState({date: event.target.value});
    }

    handleFilterFormTime(event){

        this.setState({formTime: event.target.value});
    }

    handleFilterToTime(event){

        this.setState({toTime: event.target.value});

    }

    render() { 

        return (       
            <div className="pb-3 pt-3">                      
                <form onSubmit={this.handleFilterSubmit.bind(this)}>                  

              <Row>
                  <Col md={4}>                    
                      <select className="custom-select" name="user" onChange={this.handleFilterUserChange.bind(this)} required>
                          <option value="">Select an employee...</option>
                          { this.state.users.map( ( user  ) => 
                              <option key={user.id} value={user.id}>{user.name}</option>     
                          )}
                      </select>
                  </Col>
                  <Col md={2}>  
                    <Input 
                      type="date"
                      name="Date"        
                      onChange={ this.handleFilterDate.bind(this) }                                         
                    />                   
                  </Col>
                  <Col md={2}>  
                  <Input 
                      type="time"
                      name="FormTime"        
                      onChange={ this.handleFilterFormTime.bind(this) }                                         
                    />           
                  </Col>
                  <Col md={2}>  
                    <Input 
                      type="time"
                      name="ToTime"        
                      onChange={ this.handleFilterToTime.bind(this) }                                         
                    />                   
                  </Col>
                  <Col md={2}>
                      <button className="btn btn-primary btn-block">Filter</button>
                  </Col>
              </Row>             
          </form>
            
            </div>
        );
    }
}
 
export default Filter;