import React, { Component} from 'react';
import { Link, withRouter } from 'react-router-dom';
import Auth from '../../services/auth';

class Header extends Component {
    
    constructor() {
        super(); 
        this.state = {}
    }


    LogOut(e) {
        e.preventDefault();
        Auth.logout();
        this.props.history.push("/login");
    }
    
    render() { 
        return ( 
        
        <div className="row border-bottom">
            <nav className="navbar navbar-static-top" role="navigation">
            <h2 className="ext-muted  nav_title">KPS tracker</h2>
            
            <ul className="nav navbar-top-links navbar-right pr-2">		
                <li><Link className="dropdown-item" to='/change-password'>Change Password</Link></li>
                <li><Link className="dropdown-item" to='/logout' onClick={this.LogOut.bind(this)} >Logout</Link></li>
            </ul>
        
            </nav>
        </div> 
      );
    }
}
 
export default withRouter(Header);
