import React, { Component } from 'react'; 
import { Form , Button, FormGroup, Input } from 'reactstrap'; 
import { withRouter } from "react-router-dom";


import Auth from '../../../services/auth';
 
class Login extends Component {  
    
    constructor() {
        super(); 
        this.state = { 
            credentials: {
                phone: '',
                password: '',
                remember: false
            },            
            loader: false,
            error: false,
            errorMessage: '',
        };    
    }

    componentDidMount() {

        document.body.classList.add('gray-bg');
        document.title = "Login || KPS" 

    } 

    isValid(){

        if (this.state.credentials.phone && this.state.credentials.password) { 
            return true
        }
        return false;
    }
 
    async handleLogIn(e) {         

        e.preventDefault();
        this.setState({Loader : true});

        if(this.isValid()){

            const response = await Auth.login(this.state.credentials);

            if(response.error === false){

                if(response.results.user_role === 'Admin'){

                    Auth.onLoginSuccess(response.results, this.state.credentials.remember);
                    this.props.history.push('/');

                }else{
                    this.setState( { error: true, Loader : false, errorMessage: "You are not authorized!" });
                    return false;
                }
               
            }else{

                this.setState( { error: true, Loader : false, errorMessage: response.message });
                return false;
            }
        }else{
            this.setState( { error: true, Loader : false, errorMessage: "All fields are required!" });
            return false;
        }
    }

    phoneHandler = (event) => {

        let phone = event.target.value ;

        this.setState(prevState => ({
            credentials: { ...prevState.credentials, phone }
        }));
    }

    passwordHandler = (event) => {

        let password = event.target.value ;

        this.setState(prevState => ({
            credentials: { ...prevState.credentials, password }
        }));
    }

    rememberHandler = (event) => {

        let remember = event.target.checked ;

        this.setState(prevState => ({
            credentials: { ...prevState.credentials, remember }
        }));
    }

    render() {   
         
        return (            
            <div className="middle-box text-center loginscreen pt-5">                                

                <div className="mb-4 pt-5">
                    <h2>Welcome to KPS tracker</h2>
                </div>                       

                { (this.state.error) ?
                   <div className="ErrorMassge">{this.state.errorMessage}</div>     : ''          
                }
                         
                
                <Form onSubmit={ this.handleLogIn.bind(this) }>

                    <FormGroup>           
                        <Input placeholder="Phone" onChange= { this.phoneHandler }/> 
                    </FormGroup>

                    <FormGroup>
                        <Input type="Password" placeholder="Password" onChange={this.passwordHandler}/> 
                    </FormGroup>

                    <FormGroup>
                         <Input type="checkbox" onChange={this.rememberHandler}/> Remember Me
                    </FormGroup>

                    <Button className="btn btn-primary w-100" variant="primary" type="submit">Login</Button>

                    {this.state.Loader ? (
                        <div className="LoadingIcon">
                            <img src="../images/loader.svg" alt="Loading"/>
                        </div>
                    ) : ( '' )}                        
                
                </Form> 
            </div>           
        )
    }
} 

export default withRouter(Login);