import React, { Component } from 'react';

import Header from '../../partials/Header';
import Footer from '../../partials/Footer';
import Filter from './Filter';
import MapContainer from './map/MapContainer';


export default class Dashboard extends Component {  

    state = {
        singleUserData: [],
        isFilteredApplied: false
    }

    userLocationsCallback = (locations, isFilteredApplied) => {
        
        this.setState( { singleUserData: locations } );
        this.setState( { isFilteredApplied: true });
        
    }


    render() {
        return (
            <div id="wrapper">       
                <div id="page-wrapper" className="gray-bg dashbard-1">
                    <Header/>
                    <div className="wrapper wrapper-content animated fadeInRight">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="ibox">                          
                                        <div className="ibox-content"> 
                                            <Filter  
                                                callback = {this.userLocationsCallback}/>
                                            <MapContainer 
                                                singleData= {this.state.singleUserData} 
                                                isFiltered = {this.state.isFilteredApplied} />
                                        </div>
                                    </div>
                                </div>                
                            </div>      
                        </div>    
                    <Footer/>
                </div>	
            </div>
        );
    }
}
