import CookieService from './CookieService';
import axios from 'axios';

const expiredAt = 60*24*60*1000;

class Auth {

       
    constructor(){
        const token = CookieService.get('token');
        (token) ? this.authenticated = true : this.authenticated = false;
    }

 

    async login(credentials){
        
        const loginURL = "https://tracking-service.kpstone.org/api/v1/login";

        try{

            const response =  await axios.post(loginURL, credentials, {
                headers: {
                    'Content-Type': 'application/json',
                } 
            });

            return response.data;

        }catch(error){
            return error.response.data;
         }  

    }

    onLoginSuccess(data, remember){

        let options = { path : '/', sameSite: 'lax'}

        if(remember){
            let date = new Date();
            date.setTime(date.getTime() + expiredAt);
            options.expires =  date;
        }       
        CookieService.set('token', data.access_token, options);
        
        return true;

    }

    logout() {
        CookieService.remove('token');
    }

    isAuthenticated(){
        return this.authenticated; 
    }

    getAccessToken(){
        return  CookieService.get('token');
    }

}

export default new Auth();