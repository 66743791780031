import React from 'react';
import { Redirect } from 'react-router-dom';
import Auth from './auth';


class ProtectedRoute extends React.Component {

    render() {
        const Component = this.props.component;
        const isAuthenticated = Auth.isAuthenticated();
       
        return isAuthenticated ? (
            <Component />
        ) : (
            <Redirect to={{ pathname: '/login' }} />
        );
    }
}

export default ProtectedRoute;