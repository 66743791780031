import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ProtectedRoute from './services/ProtectedRoute';

import Dashboard from './components/pages/dashboard/Dashboard';
import Login from './components/pages/users/Login';
import ChangePassword from './components/pages/users/ChangePassword';

function App() {
  return (
    <div>        
        <Router>
          <Switch>
            <ProtectedRoute exact path="/" component={Dashboard} />
            <Route exact path="/login" component={Login} />
            <ProtectedRoute exact path="/change-password" component={ChangePassword} />
          </Switch>            
        </Router>
    </div>
  );
}

export default App;
