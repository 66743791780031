import React from 'react';
import { Marker} from '@react-google-maps/api';
import RedPin from '../../../../asset/images/pinRed.png';
import YellowPin from '../../../../asset/images/pinYellow.png';


const MapMarker = (props) =>  { 

    let title = '';
    
     (props.data.name) ? 
            title = props.data.name + ' - '+ props.data.date+' @'+props.data.time
        : 
            title = props.data.date+' @'+props.data.time;

    let lat = parseFloat(props.data.latitude);
    let lng = parseFloat( props.data.longitude);

        return (
            <Marker
                title={title}
                position={{lat, lng}}
                icon={{
                    url: (props.last === "last") ? YellowPin : RedPin,
                }}
            >
            </Marker>
        
        );
      
}

export default MapMarker;