import React  from 'react';
import { GoogleMap } from '@react-google-maps/api';
import { v4 as uuidv4 } from 'uuid';

import MapMarker from './MapMarker';
import PolylineMarker from './PolylineMaker';


const SingleUserMap = (  props ) => {    

    if(props.data){
      var locations = props.data[0].locations;

      var center = {
        lat: parseFloat(locations[0].latitude),
        lng: parseFloat(locations[0].longitude)
      };
    }

    

    const containerStyle = {
        width: '100%',
        height: 'calc(100vh - 250px)',
        position: 'relative'
      };
    
     

     
    var lineCoordinates =[];

    return (

       <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={14}
        >
        {  locations.map((location, i) => (
          
            <div key= {uuidv4()}>  
              {
                (i === Number(locations.length -1 )) 
                ?                 
                   <MapMarker key={uuidv4()} data={location} last= { "last" }/>
                :                 
                   <MapMarker key={uuidv4()} data={location}/> 
              }              

             {
              lineCoordinates.push(
                  { lat:  parseFloat(locations[i].latitude), lng:  parseFloat(locations[i].longitude) },
              )
            }
            </div>
          ))      

        }
          <PolylineMarker lineCoordinates={lineCoordinates}/>
        </GoogleMap>
    );
}

export default SingleUserMap;